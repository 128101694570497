<template>
    <el-card v-loading="loading" class="v_menu-list" >
        <div class="g-flex" slot="header">
            <span>菜单管理</span>
            <div class="g-flex-justify-end g-flex-1">
                <el-button size="mini" type="danger" @click="del()">删除菜单</el-button>
                <el-button size="mini" type="primary" @click="edit()">编辑菜单</el-button>
                <el-button size="mini" type="success" @click="save(0)">新增菜单</el-button>
            </div>
        </div>
        <el-row :gutter="24">
            <el-col :span="12">
                <el-tree :data="MenuList" @node-click="handleNodeClick" :expand-on-click-node="false" node-key="id" highlight-current :props="menu_props">
                    <template slot-scope="{node}">
                        <span :class="nodeColor(node)">{{node.label}}</span>
                    </template>
                </el-tree>
            </el-col>
            <el-col :span="12">
                <el-form ref="form" :model="form" label-width="150px">
                    <el-form-item label="id">
                        <el-input v-model="form.id" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-radio-group v-model="form.type">
                            <el-radio :label="1">菜单</el-radio>
                            <el-radio :label="2">api接口</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="父id">
                        <el-input v-model="form.parent_id" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="标题">
                        <el-input v-model="form.title" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item v-if="form.type==1" label="图标">
                        <el-input v-model="form.icon" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="路径">
                        <el-input v-model="form.path" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item v-if="form.type==1" label="组件名">
                        <el-input v-model="form.component" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="排序">
                        <el-input v-model="form.sort" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item v-if="form.type==1" label="菜单显示">
                        <el-switch v-model="form.is_show" :active-value="1" :inactive-value="0">
                        </el-switch>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
    import { apiGetMenuList, apiSaveMenu, apiDelMenu } from '@/utils/api.js';
    export default {
        name: "MenuList",
        created(){
            this.getList()
        },
        data() {
            //当前时间戳毫秒
            return {
                auth: {
                    save: this.$global.ApiList.includes(22),
                    del: this.$global.ApiList.includes(23),
                },
                loading:false,
                form: {
                    id: 0,
                    type:1,
                    title: '',
                    parent_id: 0,
                    path:'',
                    icon:'',
                    component:'',
                    is_show:1,
                    sort:0
                },
                MenuList: [],
                menu_props: {
                    children: 'children',
                    label: 'title'
                }
            }
        },
        methods: {
          // 获取菜单信息
          async getList() {
            const { success, data } = await apiGetMenuList()
            if(!success) return
            this.MenuList = this.tree(data.list,0)
          },

          // 处理成树形结构
          tree(MenuArr, parent_id) {
              //菜单
              let MenuList = []
              MenuArr.forEach(item => {
                  if (item.parent_id == parent_id) {
                      let children
                      children = this.tree(MenuArr, item.id)
                      if (children.length > 0) item.children = children
                      MenuList.push(item)
                  }
              })
              return MenuList
          },

          nodeColor(node) {
              if(node.data.type==1){
                  if (node.data.is_show == 0) {
                      return 'tree-hide'
                  }
              }else{
                  return 'tree-api'
              }
          },
          //节点选中
          handleNodeClick(data) {
              this.form=Object.assign({},data)
              delete this.form.children
          },
          //保存
          save(id) {
              this.$confirm('将为你添加新的菜单?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
              }).then(async () => {
                  this.loading = true
                  this.form.id = id
                  const { success, data } = await apiSaveMenu(this.form)
                  if(!success) return this.loading = false
                  this.loading = false
                  this.$message.success(data.msg)
                  this.form.id = data.id
                  this.getList()
              }).catch(() => {})
          },

          //编辑
          edit() {
              if (this.form.id == 0) {
                  this.$message.info('请选择要编辑的菜单!')
              } else {
                this.$confirm('将为你编辑该菜单?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
              }).then(async () => {
                  this.loading = true
                  const { success, data } = await apiSaveMenu(this.form)
                  if(!success) return this.loading = false
                  this.loading = false
                  this.$message.success(data.msg)
                  this.form.id = data.id
                  this.getList()
              }).catch(() => {})
              }
          },
          //删除
          del() {
              if (this.form.id == 0) {
                  this.$message.info('请选择要删除的菜单!')
              }else{
                  this.$confirm('将永久删除当前选中节点, 是否继续?', '删除提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                  }).then(async () => {
                    this.loading = true
                    const { success, data } = await apiDelMenu({id:this.form.id})
                    if(!success) return this.loading=false
                    this.loading = false
                    this.$message.success(data.msg)
                    this.getList()
                  }).catch(() => {})
              }
          }
        }
    }
</script>

<style lang="scss">
    .v_menu-list {
        .tree-hide {
          color: #409EFF;
        }

        .tree-api {
          color: #F56C6C;
        }
    }
</style>